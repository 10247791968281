import Copy from '../../../elements/Copy';
import {
  FilterState,
  useProductsContext
} from '../../../hooks/useProductsContext';
import { FilterLabel } from '@raylo-tech/raylopay-ui';
import {
  StyledAppliedFiltersClearAllButton,
  StyledAppliedFiltersWrapper,
  StyledAppliedFiltersCurrentFilters,
  StyledAppliedFiltersClearAllButtonWrapper
} from './styles';
import { useRef } from 'react';
import { getCategoryName } from '@/utils/categoryHelpers';
import { capitalize } from '../../../utils/strings.utils';

const AppliedFilters = () => {
  const { filters, toggleFilters, resetFilter } = useProductsContext();
  const heightRef = useRef(null);

  const appliedFilters = Object.entries(filters).flatMap(
    ([filterType, values]) =>
      values.map(value => ({
        filterType,
        value,
      }))
  );

  const label = (value: string, filterType: string): string => {
    if (filterType === 'monthlyprice') {
      if (value.toLowerCase() === 'pre-approved') {
        return 'Pre-approved';
      }
      return `£${value}`;
    } else if (filterType === 'condition') {
      switch (value.toUpperCase()) {
        case 'NEW':
          return 'Brand new';
        case 'REFURBISHED':
          return 'Refurbished';
      }
    } else if (filterType === 'category') {
      return getCategoryName(value);
    } else if (filterType === 'model') {
      return value;
    } else if (filterType === 'make') {
      return value.toUpperCase();
    }

    return capitalize(value);
  };

  const maxHeight = heightRef?.current ? (heightRef.current as HTMLDivElement).scrollHeight + 10 : 100;

  return (
    <StyledAppliedFiltersWrapper
      isOpen={appliedFilters.length > 0}
      ref={heightRef}
      maxHeight={maxHeight}
      data-testid="applied-filters"
    >
      <Copy bold fontSize={16} lineHeight={20} textWrap="nowrap">
        {appliedFilters.length} applied{' '}
        {appliedFilters.length === 1 ? 'filter' : 'filters'}
      </Copy>
      <StyledAppliedFiltersClearAllButtonWrapper>
        <StyledAppliedFiltersClearAllButton
          data-testid="clear-all"
          onClick={resetFilter}
        >
          Clear All
        </StyledAppliedFiltersClearAllButton>
      </StyledAppliedFiltersClearAllButtonWrapper>
      <StyledAppliedFiltersCurrentFilters>
        {appliedFilters.map(
          ({ filterType, value }, i) =>
            value && (
              <FilterLabel
                dataTestId={`${filterType}-${value}-${i}`}
                key={value}
                copy={label(value, filterType)}
                isSelected={false}
                onSelect={() =>
                  toggleFilters(filterType as keyof FilterState, value)}
                showClose
              />
            )
        )}
      </StyledAppliedFiltersCurrentFilters>
    </StyledAppliedFiltersWrapper>
  );
};
export default AppliedFilters;
