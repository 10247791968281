import { ProductsProvider } from '@/hooks/useProductsContext';
import { useAppContext } from '@/hooks/useAppContext';
import ProductsGrid from '@/components/productsgrid';
import FilterWrapperModal from '@/components/ProductFilter/FilterWrapperModal/FilterWrapperModal';
import { useCustomerContext } from '@/hooks/useCustomerContext';
import { AboutYourLimitModal, NotApprovedDeviceModal, PreApprovedHeaderBanner } from '@/components/AddTech';
import { VariantForPLP } from '@/types/productTypes';
import PageMeta from '@/components/PageMeta';
import ProductsWrapper from '@/components/ProductsWrapper';
import IntercomWrapper from '@/components/IntercomWrapper/IntercomWrapper';
import { ASSETS_IMAGES_BASE_URL } from '@raylo-tech/raylopay-ui';

type ProductsContainerProps = {
  products: VariantForPLP[];
};

export const ProductsContainer = ({ products }: ProductsContainerProps) => {
  const { modalOpen } = useAppContext();
  const { eligibleForAddTech, formattedPreApprovedAmount } = useCustomerContext();

  const showPreApprovedAmountBanner = eligibleForAddTech && formattedPreApprovedAmount;

  return (
    <>
      <PageMeta
        title="Products | Raylo"
        metaTitle="Choose from our range of brand new phones and refurbished phones"
        metaDescription="We offer both brand new phones and refurbished SIM-free phones"
        metaImage={`${ASSETS_IMAGES_BASE_URL}/raylo-og-image.avif`}
      />
      <IntercomWrapper>
        <ProductsWrapper context="products">
          <ProductsProvider productData={products}>
            {showPreApprovedAmountBanner && <PreApprovedHeaderBanner formattedAmount={formattedPreApprovedAmount} />}
            <ProductsGrid />
            {modalOpen === 'filters' && <FilterWrapperModal />}
            <AboutYourLimitModal formattedAmount={formattedPreApprovedAmount} />
            <NotApprovedDeviceModal formattedAmount={formattedPreApprovedAmount} />
          </ProductsProvider>
        </ProductsWrapper>
      </IntercomWrapper>
    </>
  );
};
