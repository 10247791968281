import { formatDisplayPrice } from '../../utils/formatDisplayPrice';
import { formatImageUrl } from '@/utils/urls.utils';
import { IImageCard } from './types';
import ProductCardContent from './ProductCardContent';
import { useProductsContext } from '../../hooks/useProductsContext';
import { useCustomerContext } from '../../hooks/useCustomerContext';
import { PreApprovedBadgeContainer } from '../AddTech';
import { useAppContext } from '../../hooks/useAppContext';
import Container from '../../elements/Container';
import { Link } from '../../elements/Link/Link';
import { useConsumerTypeContext } from '@/hooks/useConsumerTypeContext';

export const ProductCard = ({
  dataTestId,
  imageUrl,
  displayName,
  productVariantConditions,
  lowestMonthlyCost,
  variantSlug,
  cardDisplaySpecification,
  make,
  colours,
  eligible,
  isPriorityImage,
}: IImageCard) => {
  const url = formatImageUrl(imageUrl);
  const displayPrice = formatDisplayPrice(lowestMonthlyCost);
  const { consumerTypePrefixPath, consumerIsBusiness } = useConsumerTypeContext();
  const { shouldDisplayPrices } = useProductsContext();
  const { eligibleForAddTech } = useCustomerContext();
  const { setModalOpen } = useAppContext();

  const content = (
    <ProductCardContent
      dataTestId={dataTestId}
      productVariantConditions={productVariantConditions}
      make={make}
      displayName={displayName}
      displayPrice={displayPrice}
      cardDisplaySubtitle={cardDisplaySpecification}
      url={url}
      colours={colours}
      shouldDisplayPrices={shouldDisplayPrices}
      variantSlug={variantSlug}
      isPriorityImage={isPriorityImage}
      priceSuffix={consumerIsBusiness ? 'excl. VAT' : undefined}
    />
  );

  if (eligibleForAddTech) {
    if (eligible) {
      return (
        <PreApprovedBadgeContainer dataTestId={`${dataTestId}-eligible`}>
          <Link href={consumerTypePrefixPath(`/products/${variantSlug}`)} data-testid={dataTestId}>
            {content}
          </Link>
        </PreApprovedBadgeContainer>
      );
    }

    return (
      <Container onClick={() => { setModalOpen('notApprovedDevice'); }} dataTestId={`${dataTestId}-ineligible`}>
        {content}
      </Container>
    );
  }

  return (
    <Link href={consumerTypePrefixPath(`/products/${variantSlug}`)} data-testid={dataTestId}>
      {content}
    </Link>
  );
};
